/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import 'bootstrap/dist/css/bootstrap.min.css'
// import './src/styles/bootstrap.min.css'
// import './src/styles/main.css'
// You can delete this file if you're not using it

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider
