import { INCREASE } from "./types";

const initialState = {
  count: 1
};

export const mainReducer = (state = initialState, action) =>{
  switch (action.type){
    case INCREASE: {
      return {
        count:  state.count + 1
      }
    }
    default: return state;
  }

}
