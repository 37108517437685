module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Строймаркет г. Жабинка","short_name":"Строймаркет","start_url":"/","background_color":"#2b2a29","theme_color":"#fa9255","display":"minimal-ui","icon":"src/images/shop-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3dfe28633226243299efac4cf74a3f70"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
